import {
  SET_SEARCH_FILTERS_LOADING_STATUS,
  LOAD_SEARCH_FILTERS,
  LOAD_SEARCH_INSTRUCTORS,
  SET_SHOW_ALERT
} from '../constants/action-types';

import {
  fetchJsonSearchFilters,
  fetchJsonSearchInstructors
} from '../api'

import {
  setErrors,
  setSuccessMessage,
  resetAllMessages
} from '.';

const loadSearchFilters = filters => ({
  type: LOAD_SEARCH_FILTERS,
  filters
});

const loadSearchInstructors = instructors => ({
  type: LOAD_SEARCH_INSTRUCTORS,
  instructors
});


const setSearchFiltersLoadingStatus = isLoading => (
  {
    type: SET_SEARCH_FILTERS_LOADING_STATUS,
    isLoading
  }
);

const setShowAlert = showAlert => (
  {
    type: SET_SHOW_ALERT,
    showAlert
  }
);

export const setShowAlertMessage = (showAlert) => dispatch => {
  dispatch(setShowAlert(showAlert));
};

export const fetchAndLoadSearchFilters = (force = false) => (dispatch, getState) => {
  const filters = getState().filters;
  if (filters.loaded && !force) {
    return false;
  }
  dispatch(setSearchFiltersLoadingStatus(true));
  fetchJsonSearchFilters().then(filters => {
    dispatch(loadSearchFilters(filters));
  }).catch(errors => {
    dispatch(setErrors({'INTERNAL_ERROR' : errors}));
  }).finally(() => {
    dispatch(setSearchFiltersLoadingStatus(false));
  });
}

export const fetchAndLoadSearchInstructors = () => (dispatch, getState) => {
  fetchJsonSearchInstructors().then(results => {
    dispatch(loadSearchInstructors(results.instructors));
  }).catch(errors => {
    dispatch(setErrors({'INTERNAL_ERROR' : errors}));
  }).finally(() => {
    dispatch(setSearchFiltersLoadingStatus(false));
  });
}